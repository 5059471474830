import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { saasClassicTheme } from "common/theme/saasClassic";
import { ResetCSS } from "common/assets/css/style";
//import { GlobalStyle, ContentWrapper } from "Containers/Ride/ride.style";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/SaasClassic/saasClassic.style";
import { DrawerProvider } from "common/contexts/DrawerContext";
//import Navbar from "Containers/Ride/Navbar";
import Navbar from "Containers/MainNavbar";
import BannerSection from "Containers/Banner";
import FeatureSection from "Containers/Feature";
import FeatureSlider from "Containers/FeatureSlider";
import ProductSlide from "Containers/ProductSlide";
//import Footer from "Containers/Ride/Footer";
import Footer from "Containers/Footer";
import Seo from "components/seo";
import { Modal } from "@redq/reuse-modal";
import PartnerSection from "Containers/Partner";
import FaqSection from "Containers/Faq";
import ScrollItems from "../Containers/ScrollItems";
import { graphql, StaticQuery } from "gatsby";
import mainImg from "../images/main.png";
import prt1Img from "../images/partner1.png";
import prt2Img from "../images/partner2.png";
import prt3Img from "../images/partner3.png";
import prt4Img from "../images/partner4.png";
import prt5Img from "../images/partner5.png";
import prt6Img from "../images/partner6.png";
import prt7Img from "../images/partner7.png";
import konditourImg from "../images/konditour.jpg";
import { Link } from "gatsby";
import Button from "common/components/Button";

const IndexPageEn = () => {
  const [Tours, setTours] = React.useState();
  const [lang, setLang] = React.useState("He");

  const textLang = {
    toursSlogan: { En: "Our Tours", He: "הסיורים שלנו" },
    kadoshimSlogan: {
      En: "Our Tours",
      He: "ממשיכים את הסיור הקולינרי עם קרן ואיציק קדוש",
    },
    toursTitle: { En: "Choose your tour", He: "בחרו את הסיור שלכם" },
    kadoshimTitle: { En: "", He: "" },
  };
  const videoObj = {
    url: "https://www.youtube.com/embed/41ii32X0JkE",
    poster_url: mainImg,
  };

  const allPartnersImg = [
    { url: prt1Img, title: "ItravleJRS" },
    { url: prt2Img, title: "first station" },
    { url: prt3Img, title: "brown hotels" },
    { url: prt4Img, title: "eldan" },
    { url: prt5Img, title: "traveling jerusalen" },
    { url: prt6Img, title: "grafitiyul" },
    { url: prt7Img, title: "eldan hotel" },
  ];

  const allTour = [
    "1XU3bpkdsgJePawMWXB0",
    "2RsJUf7rYXZpZnkfOCqr",
    "4JlfowL5ZqGt5tF3egH1",
    "99gZnhiNu4sex1E0Ypd4",
    "COmEhNXyAKdNiB2pGanZ",
    "DVl8TCMY8VhQdN9qr1Nc",
    "FXP91pj5gLMYP9K9SYe0",
    "GO3eomYx0O9bLQluxf8J",
    "MQ9lMrABBtCvTZ1g1JyW",
    "MnmUJZoT4znQur4YQjMu",
    "QwvsqpPJMHwVQX6Qdvyr",
    "WNCL1qBE0bs5TObAGpnt",
    "WO6l9qR5pPO25Y9oIXNO",
    "XhYJ2O5vESHnjtqjrQTd",
    "cb0qYZ2TwG73wavSrIGJ",
    "f3nmoTZ0AzJDuwcJavJd",
    "lfngCB2AQGIoIdVkiIdu",
    "n0Kuwb71znhTCxu4nZQ4",
    "rljiQyhYwm2pMEnZgDaE",
    "orR8LG1DR1LDaTZ9it7w",
    "vEkQmtiVgM96L0BPuB54",
  ];

  const kadoshimTours = [
    "8Vs1GNvyr56RH3jsBq1n",
    "UdxGCzh3WBb4HvufyXI0",
    "orR8LG1DR1LDaTZ9it7w",
    "b16wOFd4frb3mLmYBV4o",
    "hoFEt5Ay3IcEbXp5qeHM",
  ];

  return (
    <StaticQuery
      query={graphql`
        query allToursMainPageHeQuery {
          allTour {
            nodes {
              all_media {
                name
                order
                poster_url
                profile
                type
                url
                poster_name
              }
              audience
              description

              distance
              duration

              hours_range {
                max
                min
              }
              id

              location
              main_sentense
              price
              rating {
                total
                votes
              }
              title
              tour_guide {
                email
                full_name
                id
                profile_image
              }
              type

              tour_image_obj {
                title
                url
              }
            }
          }
        }
      `}
      render={(data) => {
        setTours(data.allTour.nodes);

        if (!Tours) return null;

        const toursToDesplay = Tours.filter(function (tour) {
          return allTour.includes(tour.id);
        });

        const kadoshToDesplay = Tours.filter(function (tour) {
          return kadoshimTours.includes(tour.id);
        });

        return (
          <ThemeProvider theme={saasClassicTheme}>
            <Fragment>
              <Seo
                title="Dguide | Choose a destination, Tour it your way"
                description={
                  "Self guided tours in your own way and your own pace. choose tour guide and get a tour whenever you want."
                }
                lang={lang}
                meta={[
                  "סיורים",
                  "סיורים בירושלים",
                  "טיול בירושלים",
                  "טיול",
                  "טיול למשפחה",
                  "מקומות לטייל בשבת",
                  "איפה אפשר לטייל",
                  "המלצות לטיולים",
                  "טיול יום",
                  "סיור יום",
                  "אטרקציות בירושלים",
                  "סיור עצמאי",
                  "מדריך טיולים",
                  "יום כיף",
                  "מה לעשות בירושלים",
                  "סיורים מודרכים בירושלים",
                ]}
                keywords={[
                  "סיור מודרך",
                  "סיור עצמאי",
                  `מה אפשר לעשות בירושלים`,
                  `מה אפשר לעשות תל אביב`,
                  "טיולים",
                ]}
              />
              <Modal />
              <ResetCSS />
              <GlobalStyle />
              <ContentWrapper>
                <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                  <DrawerProvider>
                    <Navbar lang={lang} setLang={setLang} />
                  </DrawerProvider>
                </Sticky>
                <BannerSection
                  tourTrail={videoObj}
                  indexPage={true}
                  lang={lang}
                />
                <FeatureSection lang={lang} />
                <FeatureSlider lang={lang} />
                {Tours && (
                  <ScrollItems
                    tours={toursToDesplay}
                    lang={lang}
                    title={textLang["toursTitle"][lang]}
                    slogan={textLang["toursSlogan"][lang]}
                    carouselSelector="firstG"
                  />
                )}
                <div className="alltour_btn">
                  <Link href={`/AllToursHe`}>
                    <Button
                      className="alltour_btn"
                      title={"לכל הסיורים שלנו"}
                    />
                  </Link>
                </div>
                {Tours && (
                  <div id="scroll_items2">
                    <img
                      src={konditourImg}
                      alt="konditour Inage"
                      style={{
                        paddingTop: "80px",
                        margin: "auto",
                        display: "block",
                      }}
                    />
                    <ScrollItems
                      tours={kadoshToDesplay}
                      lang={lang}
                      title={textLang["kadoshimTitle"][lang]}
                      slogan={textLang["kadoshimSlogan"][lang]}
                      carouselSelector="secG"
                    />
                  </div>
                )}
                <div className="alltour_btn">
                  <Link href={`/culinaryTours/he/`}>
                    <Button
                      className="alltour_btn"
                      title={"לכל הסיורים של הקדושים"}
                    />
                  </Link>
                </div>
                <PartnerSection lang={lang} />
                {<ProductSlide allPics={allPartnersImg} indexPage={true} />}
                <FaqSection lang={lang} />

                <Footer lang={lang} />
              </ContentWrapper>
            </Fragment>
          </ThemeProvider>
        );
      }}
    />
  );
};
export default IndexPageEn;
